<template>
  <b-form @submit.prevent="fm_submit">
    <div class="form-group position-relative" ref="message">
      <b-form-textarea
        type="text"
        id="emailId"
        rows="5"
        max-rows="5"
        v-model="form.Message"
        ref="message_form-control"
        @blur.native="onBlur('message')"
        @focus.native="onFocus('message')"
        @blur="$v.form.Message.$touch()"
        :class="{ 'is-invalid': $v.form.Message.$error }"
        class="mb-3 rounded vgs-form-textarea"
      ></b-form-textarea>
      <div v-if="$v.form.Message.$error" class="invalid-feedback error-class">
        <span v-if="!$v.form.Message.required" class="float-left mb-2 mt-n2">{{ feedbackLabels.MessageRequired }}</span>
      </div>
      <label class="vgs-ac-label" for="emailId">{{ feedbackLabels.Message }}</label>
    </div>

    <div class="form-group feedback">
      <label class="vgs-ac-label vgs-ac-label-title" for="emailaddress">{{ feedbackLabels.ContactFormTitle }}</label>
      <b-form-group label=" ">
        <div>
          <b-form-radio
            class="custom-radio"
            v-model="selected"
            @change="resetInput('email')"
            name="some-radios"
            :value="feedbackLabels.Email"
            >{{ feedbackLabels.Email }}</b-form-radio
          >
          <b-form-radio
            class="custom-radio"
            v-model="selected"
            @change="resetInput('phone')"
            name="some-radios"
            :value="feedbackLabels.Telephone"
            >{{ feedbackLabels.Telephone }}</b-form-radio
          >
        </div>

        <b-form-input
          v-if="selected && selected == feedbackLabels.Email"
          type="text"
          id="emailaddress"
          ref="emailTelephone"
          v-model="form.contactQuery"
          :placeholder="selected"
          autocomplete="off"
          @blur="$v.form.contactQuery.$touch()"
          :class="{
            'is-invalid': selected === feedbackLabels.Email ? $v.form.contactQuery.$error : false,
          }"
          class="mb-3 rounded vgs-form-input"
        ></b-form-input>
        <div
          v-if="selected && selected == feedbackLabels.Email && $v.form.contactQuery.$error"
          class="invalid-feedback error-class"
        >
          <span v-if="!$v.form.contactQuery.required" class="float-left mb-2 mt-n2">{{
            feedbackLabels.EmailRequired
          }}</span>
          <span v-if="!$v.form.contactQuery.email" class="float-left mb-2 mt-n2">{{
            feedbackLabels.InvalidEmail
          }}</span>
        </div>

        <b-form-input
          v-if="selected && selected == feedbackLabels.Telephone"
          type="tel"
          ref="telephone"
          v-model="form.telephone"
          :placeholder="selected"
          autocomplete="off"
          maxLength="16"
          minLength="8"
          @blur="$v.form.telephone.$touch()"
          :class="{
            'is-invalid': selected === feedbackLabels.Telephone ? $v.form.telephone.$error : false,
          }"
          class="mb-3 rounded vgs-form-input"
        ></b-form-input>
        <div
          v-if="selected && selected == feedbackLabels.Telephone && $v.form.telephone.$error"
          class="invalid-feedback error-class"
        >
          <span v-if="!$v.form.telephone.required" class="float-left mb-2 mt-n2">{{
            feedbackLabels.TelephoneRequired
          }}</span>
          <span v-if="!$v.form.telephone.phoneNumberCheck" class="float-left mb-2 mt-n2">{{
            feedbackLabels.IncorrectPhoneNumber
          }}</span>
        </div>
      </b-form-group>
    </div>

    <div class="form-group feedback">
      <b-form-group v-slot="{ ariaDescribedby }" label=" ">
        <b-form-checkbox-group v-model="selectedCheckboxItem" :aria-describedby="ariaDescribedby" name="checkbox">
          <b-form-checkbox v-for="(item, index) in checkBoxArr" :key="index" :value="item.Name">
            {{ item.Name }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
      <b-form-input
        type="text"
        id="optionalfield"
        v-model="prevRoute"
        :placeholder="feedbackLabels.PreviousPageVisited"
        :aria-label="feedbackLabels.PreviousPageVisited"
        class="mb-3 rounded vgs-form-input"
      ></b-form-input>
    </div>
    <div class="vgs-error-messages" v-html="get_error_messages"></div>
    <b-button type="submit" variant="primary" class="w-100 p-3 rounded vgs-form-button">{{
      feedbackLabels.SubmitButtonText
    }}</b-button>
  </b-form>
</template>

<script>
import { vcm } from '@/mixins/vcm';
import { customLabels } from '@/mixins/customLabels';
import { required, email } from 'vuelidate/lib/validators';
import axios from 'axios';
import { mapActions } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
export default {
  mixins: [vcm, customLabels],
  name: 'FeedbackForm',
  data() {
    return {
      uuid:
        Vue.vclGet('user_state', '') === 'signed.in'
          ? Vue.vclGet('user_uuid', '')
          : '00000000-0000-0000-0000-000000000000',
      isSuccess: false,
      selected: '',
      selectedName: '',
      selectedMessage: '',
      selectedCheckboxItem: [],
      labels: {
        feedback: this.get_scope_translation(
          'Wir sind stets bemüht, unsere Dienstleistungen für Sie zu verbessern und zu erweitern. Wenn Sie auf unseren Seiten einen Fehler entdecken oder uns einen Verbesserungsvorschlag mitteilen wollen, benutzen Sie bitte dieses Formular.',
          'label'
        ),
      },
      checkBoxArr: [],
      form: {
        Message: '',
        Name: '',
        contactQuery: '',
        telephone: '',
        isSettlementCheck: false,
        isPreviousPageVisited: false,
      },
      personalEmail: '',
      personalPhone: '',
    };
  },
  validations: {
    form: {
      Message: { required },
      contactQuery: { required, email },
      telephone: {
        required,
        phoneNumberCheck: function(value) {
          if (value) {
            let phoneNumberRegex = /^\+(?:[0-9]⋅?){6,14}[0-9]$/;
            return value && phoneNumberRegex.test(value);
          } else {
            return true;
          }
        },
      },
    },
  },
  async beforeCreate() {
    try {
      const Url = process.env.VUE_APP_BASE_URL + '/v1/pages/feedback';
      const response = await axios.get(Url, {
        params: { language: `${this.$root.config.localization.language}` },
      });
      if (response.data.responseCode === '200' && response.data.response) {
        this.labels.feedback = response.data.response.Description;
      }
    } catch (e) {
      console.log('Error : ' + e);
    }
  },
  mounted: function() {
    this.checkBoxArr = [
      {
        id: 1,
        Name: this.feedbackLabels.SettlementCheck,
      },
      {
        id: 2,
        Name: this.feedbackLabels.PreviousPageVisited,
      },
    ];
    this.selected = this.feedbackLabels.Email;
    this.selectedName = this.feedbackLabels.Name;
    this.selectedMessage = this.feedbackLabels.Message;
  },
  watch: {
    UserInformation: function() {
      const isSignIn = Vue.vclGet('user_state', '') === 'signed.in';
      this.form.contactQuery = isSignIn ? this.$store.state.setting.UserInformation[0]?.Value : '';
      const userInfo = isSignIn && this.$store.state.setting?.UserInformation;
      const personalEmail = 'PersonalEMail';
      const personalPhone = 'PersonalPhone';

      const email = _.find(userInfo, { TypeName: personalEmail });
      const phone = _.find(userInfo, { TypeName: personalPhone });
      console.log({ email, phone });
      if (email && phone) {
        this.selected = this.feedbackLabels.Email;
        this.personalEmail = email?.Value || '';
        this.personalPhone = phone?.Value || '';
        this.form.telephone = phone?.Value || '';
        this.form.contactQuery = email.Value || '';
        return;
      }
      if (phone) {
        this.selected = this.feedbackLabels.Telephone;
        this.personalPhone = phone?.Value || '';
        this.form.telephone = phone?.Value || '';
        return;
      }
      if (email) {
        this.selected = this.feedbackLabels.Email;
        this.personalEmail = email?.Value || '';
        return;
      }
    },
  },
  computed: {
    prevRoute: {
      get() {
        return this.$store.state.feedback.prevRoute;
      },
      set(value) {
        this.$store.commit('feedback/SET_PREV_ROUTE', value);
      },
    },
    UserInformation() {
      return this.$store.state.setting.UserInformation;
    },
  },
  methods: {
    ...mapActions({
      sendFeedback: 'feedback/sendFeedbackAction',
      sendFeedbackEmail: 'feedback/sendFeedbackEmailAction',
    }),
    sendEmailHandler() {
      this.sendFeedbackEmail(
        this.form.contactQuery ? this.form.contactQuery : this.form.telephone.replace('+', '')
      ).then((result) => {
        if (result.responseCode === '200' && result.responseMessage === 'Ok') {
          this.isSuccess = true;
          this.add_error_message(this.feedbackLabels.EmailSendSuccess, this.isSuccess);
        } else {
          this.add_error_message(this.feedbackLabels.EmailSendSuccess, this.isSuccess);
        }
      });
    },
    resetInput(feedbackLabels) {
      if (feedbackLabels === 'email') {
        this.$refs['emailTelephone'].input = '';
        this.form.contactQuery = this.personalEmail;
      } else {
        this.$refs['telephone'].input = '';
        this.form.telephone = this.personalPhone;
      }
      //this.form.contactQuery = feedbackLabels === 'email' ? this.personalEmail : this.personalPhone;
    },
    fm_submit() {
      let emailOrPhone = '';
      if (this.selected === this.feedbackLabels.Email) {
        this.$v.form.telephone.$reset();
        //this.$v.$touch();
        this.$v.form.Message.$touch();
        this.$v.form.contactQuery.$touch();
        if (this.$v.form.Message.$invalid) {
          return;
        }
        if (this.$v.form.contactQuery.$invalid) {
          return;
        }
        emailOrPhone = this.form.contactQuery;
      }

      if (this.selected === this.feedbackLabels.Telephone) {
        this.$v.form.contactQuery.$reset();
        this.$v.form.Message.$touch();
        this.$v.form.telephone.$touch();
        if (this.$v.form.Message.$invalid) {
          return;
        }
        if (this.$v.form.telephone.$invalid) {
          return;
        }
        emailOrPhone = this.form.telephone;
      }

      let feedbackObj = {
        Message: this.form.Message,
        Name: this.form.Name,
        Contact: emailOrPhone,
        InformMatter: this.selectedCheckboxItem.toString(),
        LastPageVisited: this.prevRoute,
        UUID: this.uuid,
      };
      //submit
      this.sendFeedback(feedbackObj).then((result) => {
        if (result.responseCode === '200' && result.responseMessage === 'Ok') {
          this.isSuccess = true;
          this.add_error_message(this.feedbackLabels.EmailSendSuccess, this.isSuccess);
          this.sendEmailHandler();
        } else {
          this.add_error_message(result.responseMessage, this.isSuccess);
        }
      });
    },
    onBlur(selectElement) {
      let element = this.$refs[selectElement];
      let inputElement = this.$refs[selectElement + '_form-control'];
      if (element.classList.contains('onfocus') && !inputElement.value) {
        element.classList.remove('onfocus');
      }
    },
    onFocus(selectElement) {
      let element = this.$refs[selectElement];
      if (!element.classList.contains('onfocus')) {
        element.className += ' onfocus';
      }
    },
  },
};
</script>

<style scoped>
.feedback.form-group label,
.form-group2 label {
  display: block;
}
.feedback.form-group2 {
  width: 48.5%;
  display: inline-block;
  margin-right: 10px;
}
.feedback.form-group textarea {
  resize: none;
}
.feedback {
  text-align: left;
}
.feedback.form-group2 input[type='checkbox'] {
  width: auto;
}
.feedback.form-group .custom-checkbox {
  display: inline-block;
}
.feedback.form-group span {
  vertical-align: top;
}
#optionalfield {
  margin-top: 20px;
}
.feedback .custom-radio {
  display: inline-block;
  width: 49%;
}
.feedback .custom-radio .custom-control-label {
  padding-bottom: 0 !important;
}
.feedback.form-group > .invalid-feedback {
  display: flex;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.vgs-ac-label-title {
  margin-top: 2.2rem;
}

h1 {
  font-size: 1.875rem;
  line-height: 2.563rem;
  color: $primaryColor;
}
p {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #000;
}

.position-relative > label {
  display: block;
  position: absolute;
  text-align: left;
  top: 7px;
  left: 14px;
  font-size: 16px;
  transition: all 0.3s;
}

.onfocus > input + label,
.onfocus > textarea + label,
.onfocus > select + label {
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-webkit-transition: 0.3s all ease-in-out;
  top: -18px;
  left: 0;
  font-size: 11px;
  font-weight: bold;
}

::placeholder {
  color: #000;
}

.display-none-label {
  display: none;
}
</style>

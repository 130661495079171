<template>
   <div>
    <span v-if="this.before !== ''">{{ this.before }}&nbsp;</span>
    <router-link :to="{ name: this.route}" class="vcs-route-link">              
    <span>{{ this.text }}&nbsp;<b-icon v-bind:icon="this.icon"></b-icon></span>        
    </router-link>
    <span v-if="this.after !== ''">&nbsp;{{ this.after }}</span>
    {{ this.mode }}
   </div>
</template>

<script>


import { vcm } from '@/mixins/vcm'

export default {
  mixins: [vcm],
  name: 'RouteLink',
  props: {    
      mode: String,
      route: String,
      icon: String,      
      text: String,
      before: String,
      after: String      
  },
  data() {
    return {
    };
  },
  computed: {  
  },
  methods: {   
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/_var.scss";

.vcs-route-link { color: $appFontColor; font-weight: 600; font-size: 1rem; line-height: 1.875rem; border-bottom: 1px solid transparent; }
.vcs-route-link span { white-space: nowrap; }
.vcs-route-link:hover{
        color:$primaryColor;
    } 

.vcs-route-link:focus { outline: none; border-top: none; border-left: none;}
// .vcs-route-link:focus { outline: none; border-top: none; border-left: none; border-bottom: 1px solid $focusHighlightColor;}

</style>

<template>
  <Page :mode="userState">
    <h1>{{labels.Title}}</h1>
    <p>{{labels.Description}}</p>
    <div class="feedback-page">
      <FeedbackForm></FeedbackForm>
      <div class="back-login text-left" v-if="isLogin == 'undefined' || isLogin == ''">
        <RouteLink route="signin" icon="arrow-right" :text="signIn.GoToSignIn"></RouteLink>
      </div>
    </div>
  </Page>
</template>

<script>
import Vue from 'vue';
import Page from '@/structure/Page.vue'
import FeedbackForm from '@/forms/FeedbackForm.vue'
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'
import { customLabels } from "@/mixins/customLabels";
import axios from "axios"
import RouteLink from '@/structure/RouteLink.vue'

export default {
  mixins: [vcm,vvm, customLabels],
  name: 'Feedback',
  components: {
    Page, FeedbackForm , RouteLink
  },
  data() {
    return {
      userState: Vue.vclGet('user_state', '') === 'signed.in' ? "" : "jacket",
      labels: {
        Title: this.get_scope_translation('Feedback','label'),
        feedback: this.get_scope_translation('feedback','label'),
        Description: 'Wir sind stets bemüht, unsere Dienstleistungen für Sie zu verbessern und zu erweitern. Wenn Sie auf unseren Seiten einen Fehler entdecken oder uns einen Verbesserungsvorschlag mitteilen wollen, benutzen Sie bitte dieses Formular.',
      }
    };
  },
  async beforeCreate () {
    try {
      const Url = process.env.VUE_APP_BASE_URL + '/v1/pages/feedback'
      const response = await axios.get(Url, { params: {language: `${this.$root.config.localization.language}`} })
      if (response.data.responseCode === '200' && response.data.response) {
        this.labels.Title = response.data.response.Title
        this.labels.Description = response.data.response.Description
      }
    } catch (e) {
      console.log('Error : ' + e)
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next( vm => {
        vm.$store.commit('feedback/SET_PREV_ROUTE', from.path)
      })
  },
  destroyed() {
    localStorage.removeItem('prevPath');
  },
  computed: {
    isLogin() {
      return this.$root.get_access_token;
    }
  },
}
</script>

<style scoped>

.vgs-jacket-content h1 {
    font-size: 30px;
    margin-bottom: 30px;
    color:$primaryColor;
    text-transform: capitalize;
}
.back-login{
  margin-top: 50px;
  }

  .feedback-page {
    max-width: 66%;
    margin: 30px auto;
}

p { text-align: center; }
</style>

